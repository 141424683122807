/***********************************************/
// Capa de Servicios para el modulo de Company.

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/company");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("response.data:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewData() {
        try {
            let response = await axios.get(this.#baseUrl + "/view-data/company");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("record:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/company/${id}`);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(formData, imageFile) {
        // console.log('Adding Record.', formData);
        let result = null;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("name", formData.name);
        recordData.append("email", formData.email);
        recordData.append("address", formData.address);
        recordData.append("phone1", formData.phone1);
        recordData.append("phone2", formData.phone2);
        recordData.append("web_page", formData.web_page);
        recordData.append("facebook_profile", formData.facebook_profile);
        recordData.append("instagram_profile", formData.instagram_profile);
        recordData.append("youtube_video", formData.youtube_video);
        // console.log('Record-Data: ', recordData);
        try {
            let response = await axios.post(this.#baseUrl + "/company", recordData, {
                headers: this.header
            });
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                // console.log('Variables: %s:', imageFile);
                let data = response.data;
                let company_id = data.id;
                this.uploadImage(company_id, imageFile);
                result = response.data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }

    // Pushes posts to the server when called.
    async updateRecord(formData, imageFile) {
        let result = null;
        // console.log('Updating Record: ', formData);
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("name", formData.name);
        recordData.append("email", formData.email);
        recordData.append("address", formData.address);
        recordData.append("phone1", formData.phone1);
        recordData.append("phone2", formData.phone2);
        recordData.append("web_page", formData.web_page);
        recordData.append("facebook_profile", formData.facebook_profile);
        recordData.append("instagram_profile", formData.instagram_profile);
        recordData.append("youtube_video", formData.youtube_video);
        recordData.append('_method', 'put');
        // console.log('Record-Data: ', recordData);
        try {
            let response = await axios.post(`${this.#baseUrl}/company/${formData.id}`, recordData, {
                headers: this.header
            });
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                // console.log('Variables: %s', imageFile);
                let data = response.data;
                let company_id = data.id;
                this.uploadImage(company_id, imageFile);
                result = response.data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }

    deleteRecord(id) {
        let result = null;
        // console.log('Deleting Record: %s ', id);
        axios.delete(`${this.#baseUrl}/company/${id}`, {
          headers: this.header
        })
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    // console.log('Error: ', response);
                    result = this.handleError(error);
                }
            );
        return result;
    }

    uploadImage(company_id, imageFile) {
        if (imageFile) {
            let formData = new FormData();
            formData.append("company_id", company_id);
            formData.append("file", imageFile);
            axios.post(this.#baseUrl + '/upload-image/company', formData, {
                headers: this.header
            })
                .then(response => {
                    // console.log('Respuesta: ', response.data);
                    return response.data;
                })
                /* handle error */
                .catch(error => {
                        return this.handleError(error);
                    }
                );
        }
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            // console.log('Error response: %s', error.response.data.message);
            valueError = error.response.data.messaje;
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            valueError = error.request.data;
            // console.log(error.request);
            // console.error('Error request: ', error.request.data);
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
            // console.log('Error', error.message);
        }
        // console.log(error.config);
        return valueError;
    }
}
